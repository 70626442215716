import { expandToBackendURL } from '@plone/volto/helpers';
export const GET_EVENTS_LOCATIONS = 'GET_ERGO_EVENTS_LOCATIONS';
export const GET_EVENTS_KEYWORDS = 'GET_EVENTS_KEYWORDS';
export const GET_EVENTS_HEADQUARTERS = 'GET_EVENTS_HEADQUARTERS';
export const GET_EVENTS_MODE = 'GET_EVENTS_MODE';
export const GET_EVENTS_TOPICS = 'GET_EVENTS_TOPICS';

const getEventsFilters = (path, param, type) => {
  const pathSearchFilters = `${
    path === '/' ? '' : expandToBackendURL(path)
  }/@search-events-filters`;
  return {
    type: type,
    subrequest: path,
    request: {
      op: 'get',
      path: pathSearchFilters,
      params: {
        type: param,
      },
    },
  };
};

export const getEventsLocations = (path = '') => {
  return getEventsFilters(path, 'locations', GET_EVENTS_LOCATIONS);
};

export const getEventsKeywords = (path) => {
  return getEventsFilters(path, 'keywords', GET_EVENTS_KEYWORDS);
};

export const getEventsHeadquarters = (path) => {
  return getEventsFilters(path, 'headquarters', GET_EVENTS_HEADQUARTERS);
};

export const getEventsMode = (path) => {
  return getEventsFilters(path, 'mode', GET_EVENTS_MODE);
};

export const getEventsTopics = (path) => {
  return getEventsFilters(path, 'event_topics', GET_EVENTS_TOPICS);
};
