/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { SelectInput } from 'design-comuni-plone-theme/components';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEventsLocations,
  getEventsHeadquarters,
  getEventsMode,
  getEventsTopics,
} from '@package/actions';

const EventsFilterWidget = ({ options, value, id, onChange, filter }) => {
  const dispatch = useDispatch();
  const path = options?.path?.length > 0 ? options.path : '/';

  const selectOptions = useSelector((state) => {
    const results = {
      locations: state.searchEventsLocations,
      headquarters: state.searchEventsHeadquarters,
      mode: state.searchEventsMode,
      event_topics: state.searchEventsTopics,
    }[filter || 'locations']?.subrequests?.[path]?.result;
    if (!results) {
      return [];
    }
    if (Object.keys(results).length === 0 && results.constructor === Object) {
      return [];
    }
    return results;
  });
  useEffect(() => {
    if (filter === 'headquarters') {
      dispatch(getEventsHeadquarters(path));
    } else if (filter === 'mode') {
      dispatch(getEventsMode(path));
    } else if (filter === 'event_topics') {
      dispatch(getEventsTopics(path));
    } else {
      // 'locations'
      dispatch(getEventsLocations(path));
    }
  }, [path, filter]);
  return (
    <div className="mr-lg-3 my-2 my-lg-1 filter-wrapper select-filter">
      <SelectInput
        id={id}
        value={value}
        placeholder={options?.placeholder}
        onChange={(opt) => {
          onChange(id, opt);
        }}
        options={selectOptions?.filter((opt) => !!opt.value?.toString()) ?? []}
        isClearable={true}
      />
    </div>
  );
};

export default EventsFilterWidget;
