/**
 * CUSTOMIZATIONS:
 *  - added all sidebar elements used in "CardWithImageTemplate"
 * - added QueryWidget
 * - added sort_on and sort_order fields
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Segment, Accordion } from 'semantic-ui-react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import {
  SelectWidget,
  Icon,
  ObjectBrowserWidget,
  CheckboxWidget,
} from '@plone/volto/components';
import QueryWidget from '@plone/volto/components/manage/Widgets/QueryWidget';
import upSVG from '@plone/volto/icons/up-key.svg';
import downSVG from '@plone/volto/icons/down-key.svg';
import FiltersConfig from 'design-comuni-plone-theme/components/ItaliaTheme/Blocks/EventSearch/FiltersConfig';

const messages = defineMessages({
  help: {
    id: 'searchBlock_help',
    defaultMessage: 'Seleziona i filtri di ricerca da mostrare nel blocco.',
  },
  primary: {
    id: 'searchBlock_primarycolor',
    defaultMessage: 'Primario',
  },
  secondary: {
    id: 'searchBlock_secondaryColor',
    defaultMessage: 'Secondario',
  },
  tertiary: {
    id: 'searchBlock_tertiaryColor',
    defaultMessage: 'Ternario',
  },
  filter_one: {
    id: 'searchBlock_filter_one',
    defaultMessage: 'Filtro 1',
  },
  filter_two: {
    id: 'searchBlock_filter_two',
    defaultMessage: 'Filtro 2',
  },
  filter_three: {
    id: 'searchBlock_filter_three',
    defaultMessage: 'Filtro 3',
  },
  location_to_search: {
    id: 'location_to_search',
    defaultMessage: 'Posizione in cui cercare',
  },
  bg_color: {
    id: 'searchBlock_bg_color',
    defaultMessage: 'Colore di sfondo',
  },
  button_color: {
    id: 'searchBlock_button_color',
    defaultMessage: 'Colore del bottone',
  },
  styles: {
    id: 'searchBlock_style',
    defaultMessage: 'Aspetto',
  },
  text_filter: {
    id: 'searchBlock_text_filter',
    defaultMessage: 'Filtro di testo',
  },
  venue_filter: {
    id: 'searchBlock_venue_filter',
    defaultMessage: 'Filtro per luoghi',
  },
  date_filter: {
    id: 'searchBlock_date_filter',
    defaultMessage: 'Filtro per date',
  },
  help_filters: {
    id: 'searchEventsBlock_help_filters',
    defaultMessage:
      "Questi filtri non verranno visualizzati dall'utente ma consentono di pre filtrare gli eventi secondo alcuni criteri. Il criterio sulla posizione impostato nel campo 'Posizione in cui cercare' vince su qualsiasi altro criterio di percorso impostato nei 'Criteri'.",
  },
  sort_on: {
    id: 'sort_on',
    defaultMessage: 'Ordina per',
  },
  sort_order: {
    id: 'sort_order',
    defaultMessage: 'Ordine inverso',
  },
  sort_effective_date: {
    id: 'sort_effective_date',
    defaultMessage: 'Data di pubblicazione',
  },
  sort_modified_date: {
    id: 'sort_modified_date',
    defaultMessage: 'Data di modifica',
  },
  sort_end_date: {
    id: 'sort_end_date',
    defaultMessage: 'Data di fine di un evento',
  },
  sort_start_date: {
    id: 'sort_start_date',
    defaultMessage: 'Data di inizio di un evento',
  },
  show_default_results: {
    id: 'searchBlock_show_default_results',
    defaultMessage: 'Di default, mostra i risultati',
  },
  pre_filters: {
    id: 'searchBlock_pre_filters',
    defaultMessage: 'Filtri pre-impostati',
  },
  always_show_image: {
    id: 'always_show_image',
    defaultMessage: "Mostra l'immagine per tutti gli elementi",
  },
  natural_image_size: {
    id: 'natural_image_size',
    defineMessages: "Non alterare le dimensioni naturali dell'immagine",
  },
  show_topics: {
    id: 'show_topics',
    defaultMessage: 'Mostra gli argomenti',
  },
  set_four_columns: {
    id: 'set_four_columns',
    defaultMessage: 'Disponi su 4 colonne',
  },
  hide_dates: {
    id: 'hide_dates',
    defaultMessage: 'Nascondi le date',
  },
  show_section: {
    id: 'show_section',
    defaultMessage: 'Mostra la sezione',
  },
  show_icon: {
    id: 'show_icon',
    defaultMessage: "Mostra l'icona",
  },
  show_type: {
    id: 'show_type',
    defaultMessage: 'Mostra il tipo',
  },
  show_description: {
    id: 'show_description',
    defaultMessage: 'Mostra la descrizione',
  },
  center_cards: {
    id: 'Center block cards',
    defaultMessage: 'Centrare le card',
  },
});

const Sidebar = (props) => {
  const [activeAccIndex, setActiveAccIndex] = useState(1);

  function handleAccClick(e, titleProps) {
    const { index } = titleProps;
    const newIndex = activeAccIndex === index ? -1 : index;

    setActiveAccIndex(newIndex);
  }

  let filtersConfig = FiltersConfig(null);

  const filters = Object.keys(filtersConfig).map((k) => [
    k,
    filtersConfig[k].label,
  ]);

  const colors = [
    ['primary', props.intl.formatMessage(messages.primary)],
    ['secondary', props.intl.formatMessage(messages.secondary)],
  ];

  const sortOptions = [
    ['effective', props.intl.formatMessage(messages.sort_effective_date)],
    ['modified', props.intl.formatMessage(messages.sort_modified_date)],
    ['start', props.intl.formatMessage(messages.sort_start_date)],
    ['end', props.intl.formatMessage(messages.sort_end_date)],
  ];

  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage id="searchBlock" defaultMessage="Ricerca eventi" />
        </h2>
      </header>
      <Segment>
        <div className="ui form">
          <p className="help">{props.intl.formatMessage(messages.help)}</p>
          <SelectWidget
            id="filter_one"
            title={props.intl.formatMessage(messages.filter_one)}
            value={props.data.filter_one}
            onChange={(id, value) => {
              props.onChangeBlock(props.block, {
                ...props.data,
                filter_one: value,
              });
            }}
            required={true}
            choices={filters}
          />
          <SelectWidget
            id="filter_two"
            title={props.intl.formatMessage(messages.filter_two)}
            value={props.data.filter_two}
            onChange={(id, value) => {
              props.onChangeBlock(props.block, {
                ...props.data,
                filter_two: value,
              });
            }}
            choices={filters}
          />
          <SelectWidget
            id="filter_three"
            title={props.intl.formatMessage(messages.filter_three)}
            value={props.data.filter_three}
            onChange={(id, value) => {
              props.onChangeBlock(props.block, {
                ...props.data,
                filter_three: value,
              });
            }}
            choices={filters}
          />
          <CheckboxWidget
            id="show_default_results"
            title={props.intl.formatMessage(messages.show_default_results)}
            value={
              props.data.show_default_results
                ? props.data.show_default_results
                : false
            }
            onChange={(id, value) => {
              props.onChangeBlock(props.block, {
                ...props.data,
                [id]: value,
              });
            }}
          />
        </div>
      </Segment>
      <Accordion fluid styled className="form">
        <Accordion.Title
          active={activeAccIndex === 1}
          index={1}
          onClick={handleAccClick}
        >
          {props.intl.formatMessage(messages.pre_filters)}
          {activeAccIndex === 1 ? (
            <Icon name={upSVG} size="20px" />
          ) : (
            <Icon name={downSVG} size="20px" />
          )}
        </Accordion.Title>
        <Accordion.Content active={activeAccIndex === 1}>
          <p className="help" style={{ padding: '1rem', margin: 0 }}>
            {props.intl.formatMessage(messages.help_filters)}
          </p>

          <ObjectBrowserWidget
            id="location"
            title={props.intl.formatMessage(messages.location_to_search)}
            value={props.data.location}
            mode={'link'}
            onChange={(name, value) => {
              props.onChangeBlock(props.block, {
                ...props.data,
                location: value,
              });
            }}
          />

          <QueryWidget
            block={props.block}
            onChange={(id, value) => {
              props.onChangeBlock(props.block, {
                ...props.data,
                [id]: value,
              });
            }}
            id="defaultQuerystring"
            value={props.data.defaultQuerystring}
          />

          <SelectWidget
            id="sort_on"
            title={props.intl.formatMessage(messages.sort_on)}
            value={props.data.sort_on}
            onChange={(id, value) => {
              props.onChangeBlock(props.block, {
                ...props.data,
                sort_on: value,
              });
            }}
            choices={sortOptions}
          />
          <CheckboxWidget
            id="sort_order"
            title={props.intl.formatMessage(messages.sort_order)}
            value={props.data.sort_order ? props.data.sort_order : false}
            onChange={(name, checked) => {
              props.onChangeBlock(props.block, {
                ...props.data,
                [name]: checked,
              });
            }}
          />
        </Accordion.Content>
      </Accordion>
      <Accordion fluid styled className="form">
        <Accordion.Title
          active={activeAccIndex === 1}
          index={1}
          onClick={handleAccClick}
        >
          {props.intl.formatMessage(messages.styles)}
          {activeAccIndex === 1 ? (
            <Icon name={upSVG} size="20px" />
          ) : (
            <Icon name={downSVG} size="20px" />
          )}
        </Accordion.Title>
        <Accordion.Content active={activeAccIndex === 1}>
          <SelectWidget
            id="bg_color"
            title={props.intl.formatMessage(messages.bg_color)}
            value={props.data.bg_color}
            onChange={(id, value) => {
              props.onChangeBlock(props.block, {
                ...props.data,
                bg_color: value,
              });
            }}
            choices={colors}
          />
          <SelectWidget
            id="button_color"
            title={props.intl.formatMessage(messages.button_color)}
            value={props.data.button_color}
            onChange={(id, value) => {
              props.onChangeBlock(props.block, {
                ...props.data,
                button_color: value,
              });
            }}
            choices={[
              ...colors,
              ['tertiary', props.intl.formatMessage(messages.tertiary)],
            ]}
          />
          <CheckboxWidget
            id="center_cards"
            title={props.intl.formatMessage(messages.center_cards)}
            value={props.data.center_cards ? props.data.center_cards : false}
            onChange={(id, value) => {
              props.onChangeBlock(props.block, {
                ...props.data,
                center_cards: value,
              });
            }}
          />
          <CheckboxWidget
            id="always_show_image"
            title={props.intl.formatMessage(messages.always_show_image)}
            value={
              props.data.always_show_image
                ? props.data.always_show_image
                : false
            }
            onChange={(id, value) => {
              props.onChangeBlock(props.block, {
                ...props.data,
                always_show_image: value,
              });
            }}
          />
          <CheckboxWidget
            id="natural_image_size"
            title={props.intl.formatMessage(messages.natural_image_size)}
            value={
              props.data.natural_image_size
                ? props.data.natural_image_size
                : false
            }
            onChange={(id, value) => {
              props.onChangeBlock(props.block, {
                ...props.data,
                [id]: value,
              });
            }}
          />
          <CheckboxWidget
            id="set_four_columns"
            title={props.intl.formatMessage(messages.set_four_columns)}
            value={
              props.data.set_four_columns ? props.data.set_four_columns : false
            }
            onChange={(id, value) => {
              props.onChangeBlock(props.block, {
                ...props.data,
                [id]: value,
              });
            }}
          />
          <CheckboxWidget
            id="show_icon"
            title={props.intl.formatMessage(messages.show_icon)}
            value={props.data.show_icon ? props.data.show_icon : false}
            onChange={(id, value) => {
              props.onChangeBlock(props.block, {
                ...props.data,
                [id]: value,
              });
            }}
          />
          <CheckboxWidget
            id="show_section"
            title={props.intl.formatMessage(messages.show_section)}
            value={props.data.show_section ? props.data.show_section : false}
            onChange={(id, value) => {
              props.onChangeBlock(props.block, {
                ...props.data,
                [id]: value,
              });
            }}
          />
          <CheckboxWidget
            id="show_type"
            title={props.intl.formatMessage(messages.show_type)}
            value={props.data.show_type ? props.data.show_type : false}
            onChange={(id, value) => {
              props.onChangeBlock(props.block, {
                ...props.data,
                [id]: value,
              });
            }}
          />
          <CheckboxWidget
            id="hide_dates"
            title={props.intl.formatMessage(messages.hide_dates)}
            value={props.data.hide_dates ? props.data.hide_dates : false}
            onChange={(id, value) => {
              props.onChangeBlock(props.block, {
                ...props.data,
                [id]: value,
              });
            }}
          />
          <CheckboxWidget
            id="show_description"
            title={props.intl.formatMessage(messages.show_description)}
            value={
              props.data.show_description ? props.data.show_description : false
            }
            onChange={(id, value) => {
              props.onChangeBlock(props.block, {
                ...props.data,
                [id]: value,
              });
            }}
          />
          <CheckboxWidget
            id="show_topics"
            title={props.intl.formatMessage(messages.show_topics)}
            value={props.data.show_topics ? props.data.show_topics : false}
            onChange={(id, value) => {
              props.onChangeBlock(props.block, {
                ...props.data,
                [id]: value,
              });
            }}
          />
        </Accordion.Content>
      </Accordion>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
};

export default injectIntl(Sidebar);
