/**
 * View Accordion block.
 * @module components/ItaliaTheme/Blocks/Accordion/View
 */

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ViewBlock from 'design-comuni-plone-theme/components/ItaliaTheme/Blocks/Accordion/Block/ViewBlock';
import {
  Container,
  Card,
  CardBody,
} from 'design-react-kit/dist/design-react-kit';

/**
 * View Accordion block class.
 * @class View
 * @extends Component
 *
 * CUSTOM:
 * - Accordion header/title scrolls into the start of the page with 0.35sec of delay
 */
const AccordionView = ({ data, block }) => {
  const [itemOpen, setItemOpen] = useState(-1);
  const toggle = (index) => {
    itemOpen !== index && setTimeout(() => handleClickTop(index), 350); //il delay si fa necessario a cause del comportamento di chiusura dell'accordion
    setItemOpen(index === itemOpen ? -1 : index);
  };
  const id = new Date().getTime();
  const itemRefs = useRef([]);

  const handleClickTop = (index) => {
    const item = itemRefs.current[index];

    if (item) {
      item.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };
  return (
    <div className="block accordion">
      <div className="public-ui">
        <div className="full-width section section-muted section-inset-shadow py-5">
          <Container className="px-md-4">
            <Card className="card-bg rounded" noWrapper={false} space tag="div">
              <CardBody tag="div">
                {data.subblocks.map((subblock, index) => (
                  <div ref={(ref) => (itemRefs.current[index] = ref)}>
                    <ViewBlock
                      data={subblock}
                      toggle={() => {
                        return () => {
                          toggle(index);
                        };
                      }}
                      isOpen={itemOpen === index}
                      key={index}
                      id={id}
                      index={index}
                    />
                  </div>
                ))}
              </CardBody>
            </Card>
          </Container>
        </div>
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
AccordionView.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AccordionView;
