import {
  GET_EVENTS_KEYWORDS,
  GET_EVENTS_LOCATIONS,
  GET_EVENTS_HEADQUARTERS,
  GET_EVENTS_MODE,
  GET_EVENTS_TOPICS,
} from '@package/actions';

const initialState = {
  error: null,
  result: {},
  loaded: false,
  loading: false,
  subrequests: {},
};

const searchEventsFiltersFactory =
  (filter) =>
  (state = initialState, action = {}) => {
    switch (action.type) {
      case `${filter}_PENDING`:
        return action.subrequest
          ? {
              ...state,
              subrequests: {
                ...state.subrequests,
                [action.subrequest]: {
                  ...state.subrequests[action.subrequest],
                  result: {},
                  error: null,
                  loaded: false,
                  loading: true,
                },
              },
            }
          : {
              ...state,
              error: null,
              loading: true,
              loaded: false,
            };
      case `${filter}_SUCCESS`:
        return action.subrequest
          ? {
              ...state,
              subrequests: {
                ...state.subrequests,
                [action.subrequest]: {
                  error: null,
                  result: action.result,
                  loaded: true,
                  loading: false,
                },
              },
            }
          : {
              ...state,
              error: null,
              result: action.result,
              loaded: true,
              loading: false,
            };
      case `${filter}_FAIL`:
        return action.subrequest
          ? {
              ...state,
              subrequests: {
                ...state.subrequests,
                [action.subrequest]: {
                  error: action.error,
                  result: {},
                  loading: false,
                  loaded: false,
                },
              },
            }
          : {
              ...state,
              error: action.error,
              result: {},

              loading: false,
              loaded: false,
            };
      default:
        return state;
    }
  };

export const searchEventsHeadquarters = searchEventsFiltersFactory(
  GET_EVENTS_HEADQUARTERS,
);
export const searchEventsKeywords =
  searchEventsFiltersFactory(GET_EVENTS_KEYWORDS);
export const searchEventsLocations =
  searchEventsFiltersFactory(GET_EVENTS_LOCATIONS);
export const searchEventsMode = searchEventsFiltersFactory(GET_EVENTS_MODE);
export const searchEventsTopics = searchEventsFiltersFactory(GET_EVENTS_TOPICS);
// export const searchEventsLocations = (state = initialState, action = {}) => {
//   switch (action.type) {
//     case `${GET_EVENTS_LOCATIONS}_PENDING`:
//       return action.subrequest
//         ? {
//             ...state,
//             subrequests: {
//               ...state.subrequests,
//               [action.subrequest]: {
//                 ...state.subrequests[action.subrequest],
//                 result: {},
//                 error: null,
//                 loaded: false,
//                 loading: true,
//               },
//             },
//           }
//         : {
//             ...state,
//             error: null,
//             loading: true,
//             loaded: false,
//           };
//     case `${GET_EVENTS_LOCATIONS}_SUCCESS`:
//       return action.subrequest
//         ? {
//             ...state,
//             subrequests: {
//               ...state.subrequests,
//               [action.subrequest]: {
//                 error: null,
//                 result: action.result,
//                 loaded: true,
//                 loading: false,
//               },
//             },
//           }
//         : {
//             ...state,
//             error: null,
//             result: action.result,
//             loaded: true,
//             loading: false,
//           };
//     case `${GET_EVENTS_LOCATIONS}_FAIL`:
//       return action.subrequest
//         ? {
//             ...state,
//             subrequests: {
//               ...state.subrequests,
//               [action.subrequest]: {
//                 error: action.error,
//                 result: {},
//                 loading: false,
//                 loaded: false,
//               },
//             },
//           }
//         : {
//             ...state,
//             error: action.error,
//             result: {},

//             loading: false,
//             loaded: false,
//           };
//     default:
//       return state;
//   }
// };

// export const searchEventsKeywords = (state = initialState, action = {}) => {
//   switch (action.type) {
//     case `${GET_EVENTS_KEYWORDS}_PENDING`:
//       return action.subrequest
//         ? {
//             ...state,
//             subrequests: {
//               ...state.subrequests,
//               [action.subrequest]: {
//                 ...state.subrequests[action.subrequest],
//                 result: {},
//                 error: null,
//                 loaded: false,
//                 loading: true,
//               },
//             },
//           }
//         : {
//             ...state,
//             error: null,
//             loading: true,
//             loaded: false,
//           };
//     case `${GET_EVENTS_KEYWORDS}_SUCCESS`:
//       return action.subrequest
//         ? {
//             ...state,
//             subrequests: {
//               ...state.subrequests,
//               [action.subrequest]: {
//                 error: null,
//                 result: action.result,
//                 loaded: true,
//                 loading: false,
//               },
//             },
//           }
//         : {
//             ...state,
//             error: null,
//             result: action.result,
//             loaded: true,
//             loading: false,
//           };
//     case `${GET_EVENTS_KEYWORDS}_FAIL`:
//       return action.subrequest
//         ? {
//             ...state,
//             subrequests: {
//               ...state.subrequests,
//               [action.subrequest]: {
//                 error: action.error,
//                 result: {},
//                 loading: false,
//                 loaded: false,
//               },
//             },
//           }
//         : {
//             ...state,
//             error: action.error,
//             result: {},

//             loading: false,
//             loaded: false,
//           };
//     default:
//       return state;
//   }
// };
