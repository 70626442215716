import React from 'react';
import logo from '@package/components/layout/images/logo_footer.png';

const LogoFooter = () => (
  <figure className="icon">
    <img src={logo} width="93" height="141" alt="ER.GO Logo" />
  </figure>
);

export default LogoFooter;
