/**
 * Add your config changes here.
 * @module config
 */

import applyItaliaConfig from 'design-comuni-plone-theme/config/italiaConfig';
import '@plone/volto/config';
import TokenWidget from '@plone/volto/components/manage/Widgets/TokenWidget';

export default function applyConfig(voltoConfig) {
  let config = applyItaliaConfig(voltoConfig);

  config.settings = {
    ...config.settings,
    matomoSiteId: 'm6pMzQnqnV',
    matomoUrlBase: 'https://ingestion.webanalytics.italia.it/',
    // italiaThemeViewsConfig: {
    //   ...config.settings.italiaThemeViewsConfig,
    //   imagePosition: 'afterHeader', // possible values: afterHeader, documentBody
    // },
    siteProperties: {
      /*
        Per tutte le props di siteProperties, può essere definito un oggetto per i siti multilingua, strutturato in questo modo:
        {'default': _valore_della_prop, 'it': _valore_della_prop,...}
        Se l'oggetto non è strutturato in questo modo, viene usato l'oggetto stesso, altrimenti viene preso il valore della corrispondente alla prop della lingua corrente o alla prop 'default' se esiste.
      */
      ...config.settings.siteProperties,
      siteTitle: 'Azienda Regionale',
      siteSubtitle: 'per il Diritto agli Studi Superiori',
      parentSiteTitle: '',
      parentSiteURL: 'https://www.regione.emilia-romagna.it/',
      subsiteParentSiteTitle: 'ER.GO',
      amministrazioneTrasparenteUrl: '/amministrazione-trasparente',
      markSpecialLinks: false,
      headerslimTertiaryMenu: {
        it: [
          { title: 'Conosci ER.GO', url: '/conosci-er.go' },
          { title: 'Contatti', url: '/contatti' },
        ],
        en: [
          //{ title: 'Contacts', url: '/en/contacts' },
          //{ title: 'News', url: '/en/news' },
        ],
      },

      // enableCustomerSatisfaction: false, // false per disabilitare
      arLoginUrl: '/dossier-utente/', //se il link diventa esterno, rimettere il target blank su pulsante (LoginButton.jsx)
      arLogoutUrl: '/logout',
      spidLogin: false,
      // smallFooterLinks: {
      //   default: [{ title: 'Mappa del sito', url: '/sitemap' }],
      //   it: [{ title: 'Mappa del sito', url: '/it/sitemap' }],
      //   en: [{ title: 'Sitemap', url: '/en/sitemap' }],
      // },
    },
  };

  config.blocks.blocksConfig.listing = {
    ...config.blocks.blocksConfig.listing,
    variations: [
      ...config.blocks.blocksConfig.listing.variations,
      // {
      //   id: 'cardWithSideImageTemplate',
      //   isDefault: false,
      //   title: 'Card con immagine affiancata',
      //   template: CardWithSideImageTemplate,
      //   skeleton: CardWithSideImageTemplateSkeleton,
      //   schemaEnhancer: ({ schema, formData, intl }) => {
      //     let pos = addDefaultOptions(schema, formData, intl);
      //     addCardWithSideImageTemplateOptions(schema, formData, intl, pos);
      //     return schema;
      //   },
      // },
    ],
    listing_bg_colors: [], //{name:'blue', label:'Blu'},{name:'light-blue', label:'Light blue'},{name:'sidebar-background', label:'Grey'}
    listing_items_colors: [], //{name:'blue', label:'Blu'},{name:'light-blue', label:'Light blue'},{name:'sidebar-background', label:'Grey'}
  };

  // config.views = {
  //   ...config.views,
  // };
  config.widgets.id = {
    ...config.widgets.id,
    headquarters: TokenWidget,
  };

  config.settings['volto-gdpr-privacy'] = {
    ...config.settings['volto-gdpr-privacy'],
    defaultPanelConfig: {
      ...config.settings['volto-gdpr-privacy'].defaultPanelConfig,
      last_updated: '2023-07-06T9:00:00+00:00',
      technical: {
        ...config.settings['volto-gdpr-privacy'].defaultPanelConfig.technical,
        choices: [
          {
            config_key: 'MATOMO',
            text: {
              it: {
                title: 'Matomo',
                description:
                  "I cookie di Matomo sono usati per analizzare la navigazione sul sito al fine di migliorarla e fornire all'utente un'esperienza di navigazione migliore possibile.",
              },
              en: {
                title: 'Matomo',
                description:
                  'Matomo cookie are used to analyze navigation on the site in order to improve it and provide the user with the best possible browsing experience.',
              },
              fr: {
                title: 'Matomo',
                description:
                  "Les cookie de Matomo sont utilisés pour analyser la navigation sur le site afin de l'améliorer et offrir à l'utilisateur la meilleure expérience de navigation possible.",
              },
            },
          },
          // ...config.settings[
          //   'volto-gdpr-privacy'
          // ].defaultPanelConfig.technical.choices.filter(
          //   (f) => f.config_key !== 'GANALYTICS',
          // ),
        ],
      },
      profiling: {
        ...config.settings['volto-gdpr-privacy'].defaultPanelConfig.profiling,
        choices: [
          {
            config_key: 'YOUTUBE',
            referenceUrls: ['youtube.com', 'youtube-nocookie.com', 'youtu.be'],
            text: {
              it: {
                title: 'Youtube',
                description:
                  "I cookie di profilazione di Youtube permettono di mostrarti le pubblicità che potrebbero interessarti di più, fare analisi di accesso alla pagina e sul comportamento dell'utente, facilitare l'accesso ai servizi di Google.",
                //text to show in conditional embed if that cookies are not enabled
                conditional_embed_text:
                  'Per vedere il video, accetta i cookies di Youtube.',
              },
              en: {
                title: 'Youtube',
                description:
                  'Youtube profiling cookies allow you to show advertisements that may interest you the most, analyze page access and user behavior, facilitate access to Google services. ',
                //text to show in conditional embed if that cookies are not enabled
                conditional_embed_text:
                  'To view the video, please accept Youtube cookies.',
              },
              fr: {
                title: 'Youtube',
                description:
                  "Les cookies de profilage Youtube vous permettent d'afficher les publicités susceptibles de vous intéresser le plus, d'analyser l'accès aux pages et le comportement des utilisateurs, de faciliter l'accès aux services Google.",
                //text to show in conditional embed if that cookies are not enabled
                conditional_embed_text:
                  'Pour voir la vidéo, veuillez accepter les cookies Youtube.',
              },
            },
          },
          {
            config_key: 'VIMEO',
            referenceUrls: ['vimeo.com'],
            text: {
              it: {
                title: 'Vimeo',
                description:
                  "I cookie di profilazione di Vimeo permettono di fare analisi di accesso alla pagina e sul comportamento dell'utente, e di mostrarti le pubblicità che potrebbero interessarti di più.",
                //text to show in conditional embed if that cookies are not enabled
                conditional_embed_text:
                  'Per vedere il video, accetta i cookies di Vimeo.',
              },
              en: {
                title: 'Vimeo',
                description:
                  'Vimeo profiling cookies allow you to analyze page access and user behavior, and to show you the advertisements that may interest you the most.',
                //text to show in conditional embed if that cookies are not enabled
                conditional_embed_text:
                  'To view the video, please accept Vimeo cookies.',
              },
              fr: {
                title: 'Vimeo',
                description:
                  "Les cookies de profilage Vimeo vous permettent d'analyser l'accès aux pages et le comportement des utilisateurs, et de vous montrer les publicités qui pourraient vous intéresser le plus.",
                //text to show in conditional embed if that cookies are not enabled
                conditional_embed_text:
                  'Pour voir la vidéo, veuillez accepter les cookies Vimeo.',
              },
            },
          },
          /*{
          config_key: 'GOOGLEMAPS',
          referenceUrls: ['google.com/maps'],
          text: {
            it: {
              title: 'Google Maps',
              description:
                "I cookie di profilazione di Google permettono di fare analisi di accesso alla pagina e sul comportamento dell'utente, e di mostrarti le pubblicità che potrebbero interessarti di più.",
              //text to show in conditional embed if that cookies are not enabled
              conditional_embed_text:
                'Per vedere la mappa, accetta i cookies di Google Maps.',
            },
            en: {
              title: 'Google Maps',
              description:
                'Google profiling cookies allow you to analyze page access and user behavior, and to show you the advertisements that may interest you the most.',
              //text to show in conditional embed if that cookies are not enabled
              conditional_embed_text:
                'To view map, please accept Google Maps cookies.',
            },
            fr: {
              title: 'Google Maps',
              description:
                "Les cookies de profilage Google vous permettent d'analyser l'accès aux pages et le comportement des utilisateurs, et de vous montrer les publicités qui pourraient vous intéresser le plus.",
              //text to show in conditional embed if that cookies are not enabled
              conditional_embed_text:
                'Pour afficher la carte, veuillez accepter les cookies de Google Maps.',
            },
          },
        },*/
          /*           {
            config_key: 'META',
            referenceUrls: ['facebook.com', 'instagram.com'],
            text: {
              it: {
                title: 'Facebook e Instagram (prodotti Meta)',
                description:
                  "I cookie di profilazione di Meta permettono di fare analisi di accesso alla pagina e sul comportamento dell'utente, e di mostrarti le pubblicità che potrebbero interessarti di più, solo se sei iscritto a Facebook o Instagram o ad uno dei prodotti di Meta.",
                //text to show in conditional embed if that cookies are not enabled
                conditional_embed_text:
                  'Per vedere i contenuti di Meta (Facebook, Instagram), accetta i cookies di Meta.',
              },
              en: {
                title: 'Facebook and Instagram (Meta products)',
                description:
                  'Meta profiling cookies allow you to analyze page access and user behavior, and to show you the advertisements that may interest you the most, only if you are subscribed into Facebook or Instagram or in one of Meta products.',
                //text to show in conditional embed if that cookies are not enabled
                conditional_embed_text:
                  'To view Meta contents, please accept Meta cookies.',
              },
              fr: {
                title: 'Facebook et Instagram (produits Meta)',
                description:
                  "Les cookies de méta profilage vous permettent d'analyser l'accès aux pages et le comportement des utilisateurs, et de vous montrer les publicités susceptibles de vous intéresser le plus, uniquement si vous êtes abonné à Facebook ou Instagram ou à l'un des produits Meta.",
                //text to show in conditional embed if that cookies are not enabled
                conditional_embed_text:
                  'Pour afficher le contenu Meta, veuillez accepter les cookies Meta.',
              },
            },
          }, */
        ],
      },
    },
  };

  return config;
}
