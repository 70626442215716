import React from 'react';
import logo from '@package/components/layout/images/logo.png';

// Customization with image

const Logo = () => (
  <figure className="icon">
    <img src={logo} width="141" height="93" alt="ER.GO Logo" />
  </figure>
);

export default Logo;
