/**
 * CUSTOMIZATIONS:
 *  - added headquarters and mode filters
 *  - Changed label "Parola chiavi" in "Argomenti"
 */

//import { useSelector } from 'react-redux';
import DefaultFilters from 'design-comuni-plone-theme/components/ItaliaTheme/Blocks/EventSearch/DefaultFilters';
import EventsFilterWidget from '@package/components/Blocks/EventsSearch/widgets/EventsFilterWidget';
import EventsKeywordsWidget from '@package/components/Blocks/EventsSearch/widgets/EventsKeywordsWidget';
import { defineMessages, useIntl } from 'react-intl';

/*
  ***
  componente da customizzare nel proprio sito per modificare/aggiungere tipologie di Filtri
  ***
 */

const messages = defineMessages({
  mode_filter: {
    id: 'mode_filter',
    defaultMessage: 'Filtro per modalità',
  },
  mode: {
    id: 'mode',
    defaultMessage: 'Modalità',
  },
  headquarter_filter: {
    id: 'headquarter_filter',
    defaultMessage: 'Filtro per sede',
  },
  headquarter: {
    id: 'headquarter',
    defaultMessage: 'Sede',
  },
  subject_filter: {
    id: 'subject_filter',
    defaultMessage: 'Filtro per parole chiave',
  },
  subject: {
    id: 'subject',
    defaultMessage: 'Parole chiave',
  },
  event_topics_filter: {
    id: 'event_topics_filter',
    defaultMessage: "Filtro per tema dell'incontro",
  },
  event_topics: {
    id: 'event_topics',
    defaultMessage: "Tema dell'incontro",
  },
});

const FiltersConfig = (props) => {
  // const subsite = useSelector((state) => state.subsite?.data);
  const defaultFilters = DefaultFilters();
  const path = props?.data?.location?.[0]?.['@id'] ?? '';
  const intl = useIntl();

  return {
    ...defaultFilters,
    venue_filter: {
      ...defaultFilters.venue_filter,
      widget: {
        ...defaultFilters.venue_filter.widget,
        component: EventsFilterWidget,
        props: {
          ...defaultFilters.venue_filter.widget.props,
          filter: 'locations',
          options: {
            ...defaultFilters.venue_filter.widget.props.options,
            path,
            dispatch: null,
          },
        },
      },
    },
    headquarter_filter: {
      label: intl.formatMessage(messages.headquarter_filter),
      type: 'headquarter_filter',
      widget: {
        component: EventsFilterWidget,
        props: {
          options: {
            path,
            dispatch: null,
            placeholder: intl.formatMessage(messages.headquarter),
          },
          filter: 'headquarters',
        },
      },
      query: (value, query) => {
        if (value?.value) {
          query.push({
            i: 'headquarters',
            o: 'plone.app.querystring.operation.selection.any',
            v: value.value,
          });
        }
      },
    },
    mode_filter: {
      label: intl.formatMessage(messages.mode_filter),
      type: 'mode_filter',
      widget: {
        component: EventsFilterWidget,
        props: {
          options: {
            path,
            dispatch: null,
            placeholder: intl.formatMessage(messages.mode),
          },
          filter: 'mode',
        },
      },
      query: (value, query) => {
        if (value?.value) {
          query.push({
            i: 'mode',
            o: 'plone.app.querystring.operation.selection.any',
            v: value.value,
          });
        }
      },
    },
    keywords_filter: {
      label: intl.formatMessage(messages.subject_filter),
      type: 'keyword_filter',
      widget: {
        component: EventsKeywordsWidget,
        props: {
          value: null,
          options: {
            path,
            placeholder: intl.formatMessage(messages.subject),
          },
        },
      },
      query: (value, query) => {
        if (value?.value) {
          query.push({
            i: 'Subject',
            o: 'plone.app.querystring.operation.selection.any',
            v: value.value,
          });
        }
      },
    },
    event_topics_filter: {
      label: intl.formatMessage(messages.event_topics_filter),
      type: 'event_topics_filter',
      widget: {
        component: EventsFilterWidget,
        props: {
          value: null,
          options: {
            path,
            placeholder: intl.formatMessage(messages.event_topics),
          },
          filter: 'event_topics',
        },
      },
      query: (value, query) => {
        if (value?.value) {
          query.push({
            i: 'event_topics',
            o: 'plone.app.querystring.operation.selection.any',
            v: value.value,
          });
        }
      },
    },
  };
};

export default FiltersConfig;
